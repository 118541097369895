import { TypedContainerModule } from '@thinkalpha/common/ioc/TypedContainer.js';
import { KeycloakOAuth2ServiceImpl } from 'src/features/oauth2/lib/KeycloakOAuth2ServiceImpl';
import { appConfig } from 'src/lib/config';
import { UnreachableCaseError } from 'src/lib/util/unreachableCaseError';
import type { IsomorphicBindings } from 'src/types/bindings';

export const oAuth2Module = new TypedContainerModule<IsomorphicBindings>((bind) => {
    const oAuth2Config = appConfig.oAuth2Config;

    if (oAuth2Config.type === 'keycloak') {
        bind('OAuth2Service').to(KeycloakOAuth2ServiceImpl).inSingletonScope();
    } else {
        throw new UnreachableCaseError(oAuth2Config.type);
    }
});
