import { OAuth2CodeWrongOrExpiredError, getExpirationDate } from '..';
import type { PkcePair } from './pkce';
import * as oauth from 'oauth4webapi';
import { injectable, inject } from 'src/features/ioc';
import { OAUTH_STATE_KEY, PKCE_PAIR_KEY } from 'src/lib/constants/storage-keys';
import { localforage } from 'src/lib/serialization/localForage';
import type { OAuth2Service } from 'src/services/OAuth2Service';
import type { TokenState } from 'src/store/reducers/auth';
import type { IsomorphicBindings } from 'src/types/bindings';
import type { KeycloakConfig } from 'src/types/schemas/OAuthConfigSchema';

@injectable()
export class KeycloakOAuth2ServiceImpl implements OAuth2Service {
    private readonly client: oauth.Client;

    // Promise for issuer, since its not available immediately.
    protected readonly willBeIssuer: Promise<oauth.AuthorizationServer>;

    /**
     * Must align in all steps of authorization_code flow
     */
    protected readonly redirectUrl: string;
    protected readonly loggedOutUrl: string;
    protected scopes: Set<string> = new Set(['openid', 'offline_access']);

    #config: KeycloakConfig;
    #log: IsomorphicBindings['Logger'];

    constructor(
        @inject('ConfigService') protected readonly config: IsomorphicBindings['ConfigService'],
        @inject('LoginScreenService') protected readonly loginScreenService: IsomorphicBindings['LoginScreenService'],
        @inject('Logger') protected readonly log: IsomorphicBindings['Logger'],
    ) {
        this.#log = log.getSubLogger({ name: 'features:oauth2:lib:KeycloakOAuth2ServiceImpl' });
        // We know that this service will only be bound in the application when a keycloak config is present
        this.#config = config.oAuthConfig as KeycloakConfig;

        const { keycloakURL, tenantID, applicationID, callbackURL, loggedOutURL } = this.#config;

        const issuerUrl = new URL(tenantID, keycloakURL);

        this.client = {
            client_id: applicationID,
            token_endpoint_auth_method: 'none',
        };

        this.willBeIssuer = new Promise(async (resolve, reject) => {
            const discoveryResp = await oauth.discoveryRequest(issuerUrl, { algorithm: 'oidc' });
            const authServer = await oauth.processDiscoveryResponse(issuerUrl, discoveryResp);
            if (authServer) {
                resolve(authServer);
            } else {
                reject();
            }
        });

        this.redirectUrl = callbackURL;
        this.loggedOutUrl = loggedOutURL;
    }

    async #getLoginUrl(state: OAuth2Service.OAuthFlowState = {}) {
        const issuer = await this.willBeIssuer;
        if (!issuer.authorization_endpoint) {
            throw new Error('!issuer.authorization_endpoint', { cause: issuer });
        }
        const code_verifier = oauth.generateRandomCodeVerifier();
        const code_challenge = await oauth.calculatePKCECodeChallenge(code_verifier);

        await localforage.setItem<PkcePair>(PKCE_PAIR_KEY, {
            verifier: code_verifier,
            challenge: code_challenge,
        });
        await localforage.setItem(OAUTH_STATE_KEY, state);

        const code_challenge_method = 'S256';

        // redirect user to as.authorization_endpoint
        const authorizationUrl = new URL(issuer.authorization_endpoint);
        authorizationUrl.search = new URLSearchParams({
            client_id: this.client.client_id,
            code_challenge: code_challenge,
            code_challenge_method: code_challenge_method,
            redirect_uri: this.redirectUrl,
            response_type: 'code',
            scope: Array.from(this.scopes).join(' '),
        }).toString();

        return authorizationUrl;
    }

    // Redirect the user to the Authorization Server to start the authorization_code flow
    async startOauth(state: OAuth2Service.OAuthFlowState = {}) {
        if (TRACE) {
            this.#log.trace({ message: 'startOauth', state, stack: new Error().stack });
        }

        if (!this.scopes.has('openid')) {
            throw new Error('!this.scopes.has', { cause: this.scopes });
        }

        const authorizationUrl = await this.#getLoginUrl(state);

        if (TRACE) {
            this.#log.trace({ message: 'authorizationUrl', authorizationUrl });
        }

        this.loginScreenService.showLoginScreen(authorizationUrl.toString());
    }

    async exchangeCode(
        queryString: string,
        pkceVerifier: Promise<string> = this.getPkceVerifier(),
    ): Promise<{
        state: OAuth2Service.OAuthFlowState;
        accessToken: TokenState;
        idToken: TokenState;
        refreshToken?: TokenState;
    }> {
        if (TRACE) {
            this.#log.trace({ message: 'starting exchangeCode' });
        }

        const issuer = await this.willBeIssuer;
        const state = (await localforage.getItem<OAuth2Service.OAuthFlowState>(OAUTH_STATE_KEY)) ?? {};
        const parameters = oauth.validateAuthResponse(issuer, this.client, new URLSearchParams(queryString));

        if (oauth.isOAuth2Error(parameters)) {
            throw new Error('oauth.isOAuth2Error', { cause: parameters }); // Handle OAuth 2.0 redirect error
        }

        const response = await oauth.authorizationCodeGrantRequest(
            issuer,
            this.client,
            parameters,
            this.redirectUrl,
            await pkceVerifier,
        );

        const challenges: oauth.WWWAuthenticateChallenge[] | undefined = oauth.parseWwwAuthenticateChallenges(response);
        if (challenges) {
            for (const challenge of challenges) {
                /* eslint-disable-next-line no-console */
                console.log('challenge', challenge); // ToDo: What is this?
            }
            throw new Error('oauth.parseWwwAuthenticateChallenges', { cause: challenges }); // Handle www-authenticate challenges as needed
        }

        const data = await oauth.processAuthorizationCodeOpenIDResponse(issuer, this.client, response);
        if (oauth.isOAuth2Error(data)) {
            if (typeof data.error_reason !== 'string') {
                throw new Error(`OAuth2 error with unknown reason: ${data.error}`, { cause: data });
            }
            switch (data.error_reason) {
                case 'auth_code_not_found':
                    throw new OAuth2CodeWrongOrExpiredError(data.error_description ?? 'invalid code');
            }
            throw new Error('oauth.isOAuth2Error', { cause: data }); // Handle OAuth 2.0 response body error
        }

        return {
            state,
            accessToken: { token: data.access_token, expires: getExpirationDate(data.access_token) },
            // todo: vvv expires should not be undefined -- find the actual value somewhere
            idToken: { token: data.id_token, expires: getExpirationDate(data.id_token) },
            refreshToken: data.refresh_token ? { token: data.refresh_token, expires: undefined } : undefined,
        };
    }

    async renewToken(refreshToken: string): Promise<{ accessToken: TokenState; refreshToken?: TokenState }> {
        const issuer = await this.willBeIssuer;
        const response = await oauth.refreshTokenGrantRequest(issuer, this.client, refreshToken);
        const { access_token, refresh_token } = await oauth.processRefreshTokenResponse(issuer, this.client, response);
        const newAccessToken = access_token! as string;
        const newRefreshToken = refresh_token as string | undefined;

        return {
            accessToken: { token: newAccessToken, expires: getExpirationDate(newAccessToken) },
            // todo: vvv expires should not be undefined -- find the actual value somewhere
            refreshToken: newRefreshToken ? { token: newRefreshToken, expires: undefined } : undefined,
        };
    }

    async #revokeTokens({ accessToken, refreshToken }: { accessToken: string; refreshToken?: string }): Promise<void> {
        await Promise.all([
            fetch(this.config.oAuthConfig.tokenRevocationURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    client_id: this.client.client_id,
                    token: accessToken,
                    token_type_hint: 'access_token',
                }),
            }),
            refreshToken
                ? fetch(this.config.oAuthConfig.tokenRevocationURL, {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/x-www-form-urlencoded',
                      },
                      body: new URLSearchParams({
                          client_id: this.client.client_id,
                          token: refreshToken,
                          token_type_hint: 'refresh_token',
                      }),
                  })
                : Promise.resolve(),
        ]);
    }

    async logout({ accessToken, refreshToken }: { idToken: string; accessToken: string; refreshToken?: string }) {
        await this.#revokeTokens({ accessToken, refreshToken });
    }

    protected getPkceVerifier = async (): Promise<string> => {
        const pkce = await localforage.getItem<PkcePair>(PKCE_PAIR_KEY);
        return pkce!.verifier;
    };
}
